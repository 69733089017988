import { TrashIcon } from '@heroicons/react/24/outline';
import { Button } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

const PaginatedItems = ({ itemsPerPage,filteredOrderList,showAdd,setShowAdd,setOrderID,handlePartnerModal,userProfile,deleteOrder }) => {
      // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = filteredOrderList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredOrderList?.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredOrderList?.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <>
     
     {
            currentItems?.map((item) =>(
                <div className={`bg-white border-b-[1px]  border-gray-300 py-4 px-8 grid  gap-6 grid-flow-row grid-cols-2  items-center justify-start lg:grid-cols-8 `}>
          <Link to={`/orderDetails/${item?.lrno}`} > <p className='col-span-1 text-[0.8rem] underline hover:text-blue-400 font-[GilroyMedium]' >{item.lrno}</p></Link> 
            {/* <p className='col-span-1 text-[0.8rem] font-[GilroyMedium]' >{item.orderID}</p> */}
            <div className='col-span-1 ' >
            <p className='text-[0.7rem] font-[GilroyMedium]' >{item?.createdAt?.toDate().toDateString()}</p>
            <p className='text-[0.7rem] font-[GilroyMedium]' >{item?.createdAt?.toDate().toLocaleTimeString()}</p>
            </div>
            <p className='col-span-1 text-[0.7rem] font-[GilroyMedium]' >{item?.userName}</p>
          {showAdd === item?.lrno ? (
          
            <p  className='col-span-1 relative text-[0.7rem] font-[GilroyMedium]' >
         <p onClick={()=>setShowAdd('')} className='border-[1px] cursor-pointer absolute  right-[2px] flex items-center justify-center px-2  border-red-500 text-red-500 w-[20px] h-[20px] rounded-full' >X</p>
              <br/>
              <span className='text-[0.6rem]' >{item?.dropoff_location?.address}</span>

            </p>) : <p className='cursor-pointer text-blue-400' onClick={(e)=>setShowAdd(item?.lrno)}>Show</p>}
            <p className='col-span-1 text-[0.7rem] font-[GilroyMedium]' >{item?.pickup_location?.city},{item?.pickup_location?.state}</p>
            <p className='col-span-1 text-[0.7rem] font-[GilroyMedium]' >{item?.dropoff_location?.city},<br/>{item?.dropoff_location?.region}</p>
           
            <p className='col-span-1 text-center text-[0.7rem] font-[GilroyMedium]' >{item?.orderStatus}</p>
            <div className='flex items-center justify-center ' >
           {item?.orderStatus === "new" && <Button  onClick={()=>{
            setOrderID(item?.id)
                handlePartnerModal()
            }} className='col-span-1 text-[.7rem] font-[GilroyMedium]' >Ship Now</Button>}
             {userProfile?.role === "admin" && <div  onClick={()=>{
           deleteOrder(item?.id)
            }} className='col-span-1 text-red-600 cursor-pointer w-[1.4rem] mx-2 font-[GilroyMedium]' ><TrashIcon /></div>}
              {/* {item?.orderStatus === "pickedUp" && <Button  onClick={()=>{
            setOrderID(item?.id)
                handlePartnerModal()
            }} className='col-span-1 text-[.7rem] font-[GilroyMedium]' >View Invoice</Button>} */}
             {item?.orderStatus === "delivered" && <a className='bg-black text-[0.7rem] py-2 px-4 font-[GilroyMedium] rounded-lg text-white' href={item?.pod} download>View POD</a>}
            </div>
        </div>
            ))
        }
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="flex items-center  my-3  justify-end gap-3"
            pageClassName="flex items-center justify-center "
            pageLinkClassName="text-[.8rem] "
            previousClassName="flex text-[.8rem] items-center justify-center"
            previousLinkClassName="text-[.8rem]"
            nextClassName="flex text-[.8rem]  items-center justify-center "
            nextLinkClassName="text-[.8rem]"
            activeLinkClassName="bg-black rounded-lg py-2 text-white px-3"
      />
    </>
  )
}

export default PaginatedItems