import React, { useState } from 'react'
import TopTab from './TopTab'
import OrderTable from './OrderTable'
import { Button, Input } from '@material-tailwind/react'
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { ExportJsonCsv } from 'react-export-json-csv';
import { SiMicrosoftexcel } from "react-icons/si";
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
const Orders = ({orderList,activeTab,setActiveTab,handlePartnerModal,setOrderID,filterBySearch,filteredOrderList,deleteOrder,dateRange,setDateRange,startDate,endDate,fetchByDate,clearDate,csvOrders}) => {
    const [searchShow, setSearchShow] = useState(true)
    const headers = [
      {
        key: 'lr',
        name: 'LR No',
      },
      {
        key: 'createdAt',
        name: 'Created At',
      },
      {
        key: 'client',
        name: 'Client',
      },
      {
        key: 'delivery',
        name: 'Delivery',
      },
      {
        key: 'originPincode',
        name: 'Origin Pincode',
      },
      {
        key: 'destinationPincode',
        name: 'Destination Pincode',
      },
      
      {
        key: 'actualWeight',
        name: 'Actual Weight',
      },
      {
        key: 'chargableWeight',
        name: 'Chargable Weight',
      },
      {
        key: 'boxes',
        name: 'Boxes',
      },
      {
        key: 'status',
        name: 'Status',
      },
    ]
  return (
    <div className='lg:col-span-4 h-[105vh] mx-4 bg-gray-100' >
    <div className='mt-[10px]  ' >
    <div className='lg:grid grid-cols-6 items-center grid-flow-col' >
        <div className='col-span-1' >
            <h3 className='font-[GilroyBold] text-[1.5rem]' >Orders</h3>
        </div>
        <div className='col-span-2 flex items-center justify-start'>
            <div className="relative flex  gap-2 md:w-max">
            <Input
              type="search"
              onChange={filterBySearch}
              placeholder="lr no"
              containerProps={{
                className: "lg:min-w-[350px]",
              }}
              className=" !border-t-blue-gray-200 outline-none bg-white pl-9 placeholder:text-blue-gray-300 focus:!border-blue-gray-200"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
            <div className="!absolute left-3 top-[13px]">
             <CiSearch />
            </div>
          </div>
          
        </div>
        <div className='col-span-3 flex items-center justify-end gap-8' >
        <DatePicker
        className='px-3 border-[1px] border-gray-200 rounded-lg py-2 '
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
        
      }}
     
    />
  {searchShow ?   <Button className='' onClick={()=>{
    fetchByDate()
    setSearchShow(false)
  }} >Search</Button> : <Button className='' color='red' onClick={()=>{
    clearDate()
    setSearchShow(true)
  }} >X Clear</Button>}
 {!searchShow &&  <div className='flex px-3 py-2 rounded-lg bg-black text-white items-center justify-start' >
  <SiMicrosoftexcel className='w-[30px]  ' />
  <ExportJsonCsv headers={headers} items={csvOrders}>Export</ExportJsonCsv>
  </div>}
        </div>
        </div>
    </div>
      <TopTab activeTab={activeTab} setActiveTab={setActiveTab}  />
      <OrderTable deleteOrder={deleteOrder} filteredOrderList={filteredOrderList} handlePartnerModal={handlePartnerModal} setOrderID={setOrderID} orderList={orderList} activeTab={activeTab} />
    
    </div>
  )
}

export default Orders